import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mongoApi = createApi({
  reducerPath: 'stravaApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
  endpoints: (builder) => ({
    getSegementEfforts: builder.query({
      query: (query) => `me/segment-efforts?${new URLSearchParams(query)}`,
    }),
    getActivities: builder.query({
      query: (query) => `me/activities?${new URLSearchParams(query)}`,
    }),
    getUserTotals: builder.query({
      query: (query) => `me/totals?${new URLSearchParams(query)}`,
    }),
    getUserStats: builder.query({
      query: () => 'me/stats',
    }),
    syncStatus: builder.query({
      query: () => 'strava/sync',
    }),
    stravaToken: builder.query({
      query: () => 'me/strava-token',
    }),
  }),
});

export const {
  useGetSegementEffortsQuery,
  useGetActivitiesQuery,
  useGetUserTotalsQuery,
  useGetUserStatsQuery,
  useSyncStatusQuery,
  useLazyStravaTokenQuery
} = mongoApi;
