/* eslint-disable react/jsx-props-no-spreading */
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import Head from 'next/head';
import React from 'react';
import { Provider } from 'react-redux';
import { wrapper } from '@/redux/store';
import { Analytics } from '@vercel/analytics/react';
import { useRouter } from 'next/router';
import createEmotionCache from '../emotion/createEmotionCache';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache;
}

export const ColorModeContext = React.createContext(() => { });

export default function ViteBikeApp({
  Component,
  ...rest
}: MyAppProps) {
  const router = useRouter();
  const { store, props } = wrapper.useWrappedStore(rest);
  const { emotionCache = clientSideEmotionCache, pageProps: { session, ...pageProps } } = props;

  const [mode, setMode] = React.useState<'light' | 'dark'>('dark');
  const setColorMode = React.useCallback(
    () => setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light')),
    [],
  );

  const colors = {
    secondary: {
      main: '#ff4081', // pink
      light: '#ff79b0',
      dark: '#c60055',
      contrastText: '#ffffff',
    },
    primary: {
      main: '#00e5ff', // bright blue
      light: '#80e8ff',
      dark: '#00b2cc',
      contrastText: '#ffffff',
    },
    success: {
      main: '#00c853', // green
      light: '#69f0ae',
      dark: '#009624',
    },
    info: {
      main: '#3d5afe', // indigo
      light: '#757de8',
      dark: '#002984',
    },
    warning: {
      main: '#fdd835', // yellow
      light: '#ffee58',
      dark: '#f9a825',
    },
    error: {
      main: '#fc4c02',
      light: '#ff7d47',
      dark: '#c62000',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b6b6b6',
    },
  };

  const theme = React.useMemo(
    () => createTheme({
      components: {
        MuiButton: {
          variants: [
            {
              props: { size: 'xlarge' },
              style: {
                padding: '18px 32px',
                fontSize: '1.5rem',
              },
            },
          ],
        },
      },
      palette: {
        mode,
        ...colors,
        button: colors,
      },
    }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={setColorMode}>
      <CacheProvider value={emotionCache}>
        <Head>
          {router.pathname !== '/colorado-mountain-bikers-set-ipa-record' && (
          <>
            <title>ViteBike | Strava data on steroids</title>
            <meta name="description" content="Your Strava performance has never looked so good" />
            <meta name="viewport" content="initial-scale=1, width=device-width" />

            <meta property="og:url" content="https://www.vite.bike/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="ViteBike" />
            <meta property="og:description" content="Your Strava performance has never looked so good" />
            <meta property="og:image" content="https://www.vite.bike/img/og2.png" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="vite.bike" />
            <meta property="twitter:url" content="https://www.vite.bike/" />
            <meta name="twitter:title" content="ViteBike" />
            <meta name="twitter:description" content="Your Strava performance has never looked so good" />
            <meta name="twitter:image" content="https://www.vite.bike/img/ogimg.jpg" />
          </>
          )}

        </Head>
        <Analytics />
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <SessionProvider session={session}>
            <Provider store={store}>
              <Component {...pageProps} />
            </Provider>
          </SessionProvider>
        </ThemeProvider>
      </CacheProvider>
    </ColorModeContext.Provider>
  );
}
